<template>
  <section>
    <stockTopMenu curPosition="" windowId="musicPlayer" module="music">
      <div
        slot="descript"
        class="row align-center space-between"
        style="margin-left: 100px"
      ></div>
    </stockTopMenu>
    <section class="musicPlayer">
      <section class="musicPlayer-left">
        <div class="stylus" :class="{ rotated: isRotating }">
          <img
            src="@/assets/images/musicPlayer/stylus.png"
            alt=""
            width="50%"
          />
        </div>
        <section class="vibyl">
          <img
            class="vibyl-img"
            src="@/assets/images/musicPlayer/vinyl.png"
            width="72.5%"
            ref="rotatingImage"
            :class="{ rotating: isRotating }"
          />

          <img
            v-if="songListImg"
            :class="{ rotating2: isRotating }"
            class="vibyl-center-img"
            ref="rotatingImage"
            :src="songListImg"
            alt=""
          />
        </section>
      </section>

      <transition name="el-fade-in-linear">
        <section class="musicPlayer-right" v-show="!playlistDrawer">
          <div v-if="!needLoop" ref="musiceName" class="musice-name">
            {{ musiceName || "暂无歌曲名称" }}
          </div>
          <div v-else ref="musiceName" class="musice-name wordsCyc">
            {{ musiceName || "暂无歌曲名称" }}
          </div>
          <div class="musice-Info">
            <section>
              <div :class="['musice-text']">
                歌单: &nbsp; {{ songListName || "暂无歌单名称" }}
              </div>
              <div class="musice-text">
                歌手: &nbsp; {{ singer || "暂无歌手" }}
              </div>
            </section>
            <section class="info-rigth">
              <!-- <span>播放列表(6)</span> -->
              <el-button type="info" round @click="openPlayList"
                ><span>播放列表({{ musicList.length }})</span></el-button
              >
            </section>
          </div>
          <div class="music-ctl">
            <section class="ctlBtn">
              <el-row style="z-index: 23">
                <el-button type="text" @click="throttle(playPrevious)">
                  <img
                    src="@/assets/images/musicPlayer/playPrevious.png"
                    alt=""
                    width="90px"
                  />
                </el-button>
                <el-button v-if="!isRotating" type="text" @click="playMusice">
                  <img
                    src="@/assets/images/musicPlayer/play.png"
                    alt=""
                    width="90px"
                  />
                </el-button>
                <el-button v-else @click="playMusice" type="text">
                  <img
                    src="@/assets/images/musicPlayer/pause.png"
                    alt=""
                    width="90px"
                  />
                </el-button>
                <el-button type="text" @click="throttle(playNext)">
                  <img
                    src="@/assets/images/musicPlayer/playNext.png"
                    alt=""
                    width="90px"
                  />
                </el-button>
              </el-row>
            </section>
            <section class="music-range">
              <!-- <input type="range" min="0" max="1" step="0.01" v-model="volume" @input="setVolume">   -->
              <div style="margin-right: 5px">{{ curTime }}</div>
              <div class="progress-bar">
                <div class="progress" :style="{ width: progress + '%' }"></div>
              </div>
              <div style="margin-left: 5px">{{ endTime }}</div>
            </section>
          </div>
          <div class="songs-list">
            <section v-for="(item, index) in songList" :key="item.id">
              <div
                class="list-item"
                @click="throttle(selectSong, 1000, [item, index])"
                :title="item.albumName"
              >
                <div id="icon"></div>
                <img
                  :src="item.src"
                  style="
                    width: 220px;
                    height: 220px;
                    border-radius: 5px 5px 0 0;
                  "
                />
                <div class="item-name">
                  <span>{{ item.albumName }}</span>
                </div>
              </div>
            </section>
          </div>
        </section>
      </transition>
    </section>
    <playlistVue
      v-if="playlistDrawer"
      :musicList="musicList"
      @close="playlistClose"
      @playMusic="playMusic"
    ></playlistVue>
  </section>
</template>
<script>
let flag = true;
import { Howl } from "howler";
import stockTopMenu from "@/components/stockTopMenu.vue";
import {
  pluginMediaMusicAlbum,
  pluginMediaMusicAlbumQueryDetail,
  pluginMediaMusicInfoSubmitFailure,
  pluginMediaMusicInfoPlayMusic,
} from "@/libs/http/modules/music";
import playlistVue from "./components/playlist.vue";
export default {
  name: "musicPlayer",
  components: {
    stockTopMenu,
    playlistVue,
  },
  data() {
    return {
      musiceName: "", //歌曲名称
      songList: [], //歌单列表
      songListName: "", //歌单名称
      singer: "", //歌手
      isRotating: false, //是否在播放
      rotationDegrees: 0, //旋转角度
      Progressinterval: null, //进度条定时器
      progress: 0, //进度条
      sound: null, //播放器
      volume: 0.75, //初始音量
      endTime: "0:00", //总时间
      curTime: "0:00", //播放进度
      curIdx: 0, //当前歌曲下标
      curSongListIdx: 0, //当前播放的歌单下标
      songListImg: "", //封面图
      musicList: [],
      playlistDrawer: false,
      loading: false,
      needLoop: false,
    };
  },

  created() {
    this.fetchSongList();
  },
  beforeDestroy() {
    // 组件销毁前清除定时器

    if (this.Progressinterval) {
      clearInterval(this.Progressinterval);
    }
  },
  methods: {
    //查询歌单信息
    fetchSongList() {
      pluginMediaMusicAlbum({
        isAllFlag: "Y",
        state: "1",
      })
        .then((data) => {
          if (data && data.items && data.items[0]) {
            this.songList = data.items.map((item) => {
              return {
                id: item.albumId,
                src: item.albumImage,
                albumName: item.albumName,
              };
            });
            this.songListImg = this.songList[0]?.src || "";
            this.songListName = this.songList[0].albumName || "暂无歌单名称";
            if (this.songList[0].id) {
              this.fetchMusicDetail(this.songList[0].id);
            }
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    //歌单详情
    fetchMusicDetail(albumId, isPlay) {
      pluginMediaMusicAlbumQueryDetail({
        albumId,
      })
        .then((data) => {
          if (data.albumMusicInfoList?.length) {
            this.musicList = data.albumMusicInfoList;
            for (let i = 0; i < this.musicList.length; i++) {
              this.musicList[i].idx = i;
              this.musicList[i].playIng = false;
            }
            this.curIdx = 0;
            this.LoadingMusice();
            if (isPlay) {
              this.isRotating = true;
              this.sound.play();
            }
          } else {
            this.$message.error("暂无歌曲");
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    //播放完毕+1
    playEnd(musicId) {
      pluginMediaMusicInfoPlayMusic({
        musicId,
      })
        .then((data) => {})
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    sumbitDisabled(musicId) {
      pluginMediaMusicInfoSubmitFailure({
        musicId,
      })
        .then((data) => {})
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },

    //创建实例
    LoadingMusice() {
      const audioUrl = this.musicList[this.curIdx].musicUrl;
      this.musiceName = this.musicList[this.curIdx].musicName;
      this.singer = this.musicList[this.curIdx].singer;
      this.sound = new Howl({
        src: audioUrl,
        volume: this.volume, // 设置初始音量
        loop: false, //是否循环播放
        // mute:true,//温柔过度
        // autoplay: true,//自动播放
        //播放中
        onplay: () => {
          this.isNeedLoop();
          this.isRotating = true;
          this.setCurInd();
          this.updateProgress();
        },
        //播放暂停
        onpause: () => {
          this.isRotating = false;
          this.needLoop = false;
        },
        onloaderror: () => {
          this.isRotating = false;
          this.needLoop = false;
          this.$message.error("音频加载异常");
          this.sumbitDisabled(this.musicList[this.curIdx].id);
          this.musicList[this.curIdx].state = "-1";
          this.setCurInd();
        },
        onplayerror: () => {
          this.isRotating = false;
          this.needLoop = false;
          this.$message.error("音频播放异常");
          this.sumbitDisabled(this.musicList[this.curIdx].id);
          this.musicList[this.curIdx].state = "-1";
          this.setCurInd();
        },
        onend: () => {
          this.playNext();
          this.progress = 0;
          this.playEnd(this.musicList[this.curIdx].id);
        },
      });
    },
    /***
     * 节流函数
     * @func 必传,执行函数
     * @wait 可传,默认值1000,延迟时间
     * @return {Function} 返回值为函数
     */
    throttle(func, wait = 1000, param = "") {
      if (flag) {
        flag = false;
        setTimeout(() => (flag = true), wait);
        return func(...param);
      }
    },
    //设置播放列表当前播放歌曲
    setCurInd() {
      for (let i = 0; i < this.musicList.length; i++) {
        if (i == this.curIdx) {
          // this.musicList[i].playIng = true
          this.$set(this.musicList[i], "playIng", true);
        } else {
          // this.musicList[i].playIng = false
          this.$set(this.musicList[i], "playIng", false);
        }
      }
    },
    //下一首播放
    playNext() {
      if (!this.sound) return this.$message.error("当前没有可播放音乐");
      this.curIdx < this.musicList.length - 1
        ? this.curIdx++
        : (this.curIdx = 0);
      this.sound.unload(); //卸载当前音频
      this.sound = null;
      this.LoadingMusice();
      this.sound.play();
    },
    //上一首播放
    playPrevious() {
      if (!this.sound) return this.$message.error("当前没有可播放音乐");
      this.curIdx > 0
        ? this.curIdx--
        : (this.curIdx = this.musicList.length - 1);
      this.sound.unload(); //卸载当前音频
      this.sound = null;
      this.LoadingMusice();
      this.sound.play();
    },
    //播放选中的歌曲
    playMusic(e) {
      this.curIdx = e.idx;
      this.sound?.unload(); //卸载当前音频
      this.sound = null;
      this.LoadingMusice();
      this.sound.play();
    },
    //播放歌单
    selectSong(item, index) {
      if (index == this.curSongListIdx) return;
      this.songListImg = this.songList[index]?.src || "";
      this.songListName = this.songList[index]?.albumName || "";
      this.sound?.unload(); //卸载当前歌单播放的音频
      if (this.songList[index].id) {
        this.curSongListIdx = index;
        this.fetchMusicDetail(this.songList[index].id, "play"); //第二个参数表示需要立即播放
      }
    },
    // 播放音乐
    playMusice() {
      if (this.isRotating) {
        this.sound?.pause();
      } else {
        this.sound?.play();
      }
    },
    //打开播放列表
    openPlayList() {
      this.playlistDrawer = true;
    },
    playlistClose() {
      this.playlistDrawer = false;
    },
    //跟新进度条
    updateProgress() {
      let Eminute = Math.floor(this.sound._duration / 60);
      let Esecond = Math.floor(this.sound._duration % 60);
      this.endTime = ` ${Eminute < 10 ? "0" + Eminute : Eminute}:${
        Esecond < 10 ? "0" + Esecond : Esecond
      }`;
      this.Progressinterval = setInterval(() => {
        if (this.sound._seek >= this.sound._duration) {
          clearInterval(this.Progressinterval);
          this.progress = 0;
        } else {
          let minute = Math.floor(this.sound.seek() / 60);
          let second = Math.floor(this.sound.seek() % 60);
          this.curTime = ` ${minute < 10 ? "0" + minute : minute}:${
            second < 10 ? "0" + second : second
          }`;

          this.progress = Math.round(
            (this.sound.seek() / this.sound._duration) * 100
          );
        }
      }, 100);
    },
    //判断歌曲名是否循环
    isNeedLoop() {
      // return  true
      this.$nextTick(() => {
        let element = this.$refs.musiceName;
        if (element) {
          // 获取元素的计算样式
          const computedStyle = window.getComputedStyle(element);
          // 确保元素设置了 overflow: hidden
          if (
            computedStyle.overflow === "hidden" ||
            computedStyle.overflowX === "hidden"
          ) {
            // 获取元素的可见宽度（即容器的宽度）
            const visibleWidth = element.offsetWidth;

            // 获取元素内容的实际宽度（不考虑overflow属性）
            // 通过设置scrollWidth属性可以获取到，即使内容被隐藏了
            const contentWidth = element.scrollWidth;

            // 如果内容的实际宽度大于元素的可见宽度，则内容被隐藏了
            this.needLoop = contentWidth > visibleWidth && this.isRotating;
            return contentWidth > visibleWidth;
          }
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.musicPlayer {
  display: flex;
  background: #603d30;
  background-image: url("../../../assets/images/musicPlayer/musicBg3.jpg");
  height: 94.2vh;
  background-size: cover;
  .musicPlayer-left {
    width: 50vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    .stylus {
      transform: rotate(300deg);
      transform-origin: 30px 30px;
      transition: transform 1s ease-in-out;
      position: absolute;
      top: 7%;
      right: -7%;
      z-index: 22;
    }
    .rotated {
      transform: rotate(360deg);
    }

    .vibyl {
      z-index: 20;
      text-align: center;
      position: relative;
    }
    .rotating {
      animation: rotateRecord 15s linear infinite; /* 旋转动画 */
    }
    .rotating2 {
      transform: translate(-50%, -50%);
      animation: rotateRecord2 15s linear infinite; /* 旋转动画 */
    }
    @keyframes rotateRecord {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @keyframes rotateRecord2 {
      from {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
    .vibyl-img {
      transition: transform 0.5s ease-in-out;
    }
    .vibyl-center-img {
      width: 350px;
      position: absolute;
      height: 350px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .musicPlayer-right {
    // height: 100%;
    width: 50vw;
    overflow: hidden;
    color: white;
    .musice-name {
      font-size: 100px;
      font-weight: 600;
      margin-top: 35px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 10px;
      // animation: words 10s linear infinite;
    }
    //文字循环
    .wordsCyc {
      overflow: initial;
      animation: words 20s linear infinite;
    }
    @keyframes words {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
    .musice-Info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .musice-text {
        font-size: 28px;
        margin-bottom: 4px;
        font-weight: 550;
      }
      .info-rigth {
        // font-size: 24px;
        font-weight: 600;
        margin-right: 22px;
        span {
          font-size: 24px;
        }
      }
    }
    .music-ctl {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //   .ctlBtn {
      //   }
      .music-range {
        display: flex;
        align-items: center;
        width: 60%;
        margin-right: 40px;
        font-size: 24px;
        .progress-bar {
          width: 100%;
          border-radius: 5px;
          background-color: #847c74;
          overflow: hidden;
        }

        .progress {
          background-color: white;
          height: 18px;
          text-align: center;
          line-height: 6px;
          color: white;
        }
      }
    }
    .songs-list {
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      max-height: 54vh;
      .list-item {
        width: 220px;
        cursor: pointer;
        height: 270px;
        margin: 8px 0 0 8px;
        border-radius: 8px;
        background-image: url("../../../assets/images/musicPlayer/itemBg2.png");
        background-size: cover;
        position: relative;
        transition: background-color 0.5s ease-in-out;
        z-index: 23;
        .item-name {
          background-color: rgba(9, 9, 10, 0.5);
          width: 100%;
          font-size: 24px;
          height: 50px;
          line-height: 50px;
          text-align: left;
          position: absolute;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          bottom: 0;
          padding: 0 5px;
          border-radius: 0 0 8px 8px;
        }
      }
      #icon {
        z-index: 222;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
        border-radius: 50%;
        height: 70px;
        background-image: url("../../../assets/images/musicPlayer/playSongList.png");
        background-size: 75%;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0;
        transition: 0.3s ease-in-out;
      }
      .list-item:hover img {
        filter: blur(2px);
        transition: background-color 0.3s ease-in-out;
        position: relative;
      }
      .list-item:hover #icon {
        opacity: 1;
      }
    }
  }
}
</style>